@import '../core/theming/palette';
@import '../core/theming/theming';


@mixin mat-sidenav-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  // We use invert() here to have the darken the background color expected to be used. If the
  // background is light, we use a dark backdrop. If the background is dark, 
  // we use a light backdrop.
  $sidenav-backdrop-color: invert(mat-color($background, card, 0.6)) !default;
  $sidenav-background-color: mat-color($background, dialog) !default;
  $sidenav-container-background-color:  mat-color($background, background) !default;
  $sidenav-push-background-color: mat-color($background, dialog) !default;

  .mat-sidenav-container {
    background-color: $sidenav-container-background-color;
    color: mat-color($foreground, text);
  }

  .mat-sidenav {
    background-color: $sidenav-background-color;
    color: mat-color($foreground, text);

    &.mat-sidenav-push {
      background-color: $sidenav-push-background-color;
    }
  }

  .mat-sidenav-backdrop.mat-sidenav-shown {
    background-color: $sidenav-backdrop-color;
  }
}
