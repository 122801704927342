@import '../core/theming/palette';
@import '../core/theming/theming';


@mixin mat-list-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-list, .mat-nav-list {
    .mat-list-item {
      color: mat-color($foreground, text);
    }

    .mat-subheader {
      color: mat-color($foreground, secondary-text);
    }
  }

  .mat-divider {
    border-top-color: mat-color($foreground, divider);
  }

  .mat-nav-list .mat-list-item {
    outline: none;

    &:hover, &.mat-list-item-focus {
      background: mat-color($background, 'hover');
    }
  }
}
