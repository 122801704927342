// This mixin will ensure that lines that overflow the container will hide the overflow and
// truncate neatly with an ellipsis.
@mixin mat-truncate-line() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Mixin to provide all mat-line styles, changing secondary font size based on whether the list
// is in dense mode.
@mixin mat-line-base($secondary-font-size) {
  .mat-line {
    @include mat-truncate-line();
    display: block;
    box-sizing: border-box;

    // all lines but the top line should have smaller text
    &:nth-child(n+2) {
      font-size: $secondary-font-size;
    }
  }
}

// This mixin normalizes default element styles, e.g. font weight for heading text.
@mixin mat-normalize-text() {
  & > * {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: inherit;
  }
}

// This mixin provides base styles for the wrapper around mat-line elements in a list.
@mixin mat-line-wrapper-base() {
  @include mat-normalize-text();

  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  // Must remove wrapper when lines are empty or it takes up horizontal
  // space and pushes other elements to the right.
  &:empty {
    display: none;
  }
}
