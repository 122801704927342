@import '../core/theming/palette';
@import '../core/theming/theming';


@mixin mat-card-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-card {
    background: mat-color($background, card);
    color: mat-color($foreground, text);
  }

  .mat-card-subtitle {
    color: mat-color($foreground, secondary-text);
  }
}
