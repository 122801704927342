@import '../core/theming/palette';
@import '../core/theming/theming';


@mixin mat-radio-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-radio-outer-circle {
    border-color: mat-color($foreground, secondary-text);
  }

  .mat-radio-checked .mat-radio-outer-circle {
    border-color: mat-color($accent);
  }

  .mat-radio-disabled .mat-radio-outer-circle {
    border-color: mat-color($foreground, disabled);
  }

  .mat-radio-inner-circle {
    background-color: mat-color($accent);
  }

  .mat-radio-ripple .mat-ripple-element {
    background-color: mat-color($accent, 0.26);
  }

  .mat-radio-disabled {
    .mat-radio-ripple .mat-ripple-element, .mat-radio-inner-circle {
      background-color: mat-color($foreground, disabled);
    }

    .mat-radio-label-content {
      color: mat-color($foreground, disabled);
    }
  }
}
