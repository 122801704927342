@mixin cdk-a11y {
  .cdk-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    text-transform: none;
    width: 1px;
  }
}

/**
 * Applies styles for users in high contrast mode. Note that this only applies
 * to Microsoft browsers. Chrome can be included by checking for the `html[hc]`
 * attribute, however Chrome handles high contrast differently.
 */
@mixin cdk-high-contrast {
  @media screen and (-ms-high-contrast: active) {
    @content;
  }
}
