@import '../theming/palette';
@import '../theming/theming';

@mixin mat-option-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .mat-option {
    color: mat-color($foreground, text);

    &:hover:not(.mat-option-disabled), &:focus:not(.mat-option-disabled) {
      background: mat-color($background, hover);
    }

    &.mat-selected.mat-primary, .mat-primary &.mat-selected {
      color: mat-color($primary);
    }

    &.mat-selected.mat-accent, .mat-accent &.mat-selected {
      color: mat-color($accent);
    }

    &.mat-selected.mat-warn, .mat-warn &.mat-selected {
      color: mat-color($warn);
    }

    // In multiple mode there is a checkbox to show that the option is selected.
    &.mat-selected:not(.mat-option-multiple) {
      background: mat-color($background, hover);
    }

    &.mat-active {
      background: mat-color($background, hover);
      color: mat-color($foreground, text);
    }

    &.mat-option-disabled {
      color: mat-color($foreground, hint-text);
    }
  }
}
