@import '../core/theming/palette';
@import '../core/theming/theming';


@mixin mat-menu-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-menu-content {
    background: mat-color($background, 'card');
  }

  .mat-menu-item {
    background: transparent;
    color: mat-color($foreground, 'text');

    &[disabled] {
      color: mat-color($foreground, 'disabled');
    }

    .mat-icon {
      color: mat-color($foreground, 'icon');
      vertical-align: middle;
    }

    &:hover:not([disabled]), &:focus:not([disabled]) {
      background: mat-color($background, 'hover');
    }
  }
}
