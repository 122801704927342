@import '../core/theming/palette';
@import '../core/theming/theming';


@mixin mat-progress-bar-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .mat-progress-bar-background {
    background-image: #{_mat-progress-bar-buffer($primary, lighter)};
  }

  .mat-progress-bar-buffer {
    background-color: mat-color($primary, lighter);
  }

  .mat-progress-bar-fill::after {
    background-color: mat-color($primary);
  }

  .mat-progress-bar.mat-accent {
    .mat-progress-bar-background {
      background-image: #{_mat-progress-bar-buffer($accent, lighter)};
    }

    .mat-progress-bar-buffer {
      background-color: mat-color($accent, lighter);
    }

    .mat-progress-bar-fill::after {
      background-color: mat-color($accent);
    }
  }

  .mat-progress-bar.mat-warn {
    .mat-progress-bar-background {
      background-image: #{_mat-progress-bar-buffer($warn, lighter)};
    }

    .mat-progress-bar-buffer {
      background-color: mat-color($warn, lighter);
    }

    .mat-progress-bar-fill::after {
      background-color: mat-color($warn);
    }
  }
}

// TODO(josephperrott): Find better way to inline svgs.
// In buffer mode a repeated SVG object is used as a background.
// Each of the following defines the SVG object for each of the class defined colors.
//
// The string is a URL encoded version of:
//
// <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
//      version="1.1" x="0px" y="0px" enable-background="new 0 0 5 2"
//      xml:space="preserve" viewBox="0 0 5 2" preserveAspectRatio="none slice">
//   <circle cx="1" cy="1" r="1" fill="{INJECTED_COLOR}"/>
// </svg>
@function _mat-progress-bar-buffer($palette, $hue) {
  $color: mat-color($palette, $hue) + '';

  // We also need to escape the hash in hex colors,
  // otherwise IE will throw an XML error.
  @if str-index($color, '#') == 1 {
    $color: '%23' + str-slice($color, 2);
  }

  $result: '' +
    'data:image/svg+xml;charset=UTF-8,%3Csvg%20version%3D%271.1%27%20xmlns%3D%27http%3A%2F%2F' +
    'www.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%2' +
    '7%20x%3D%270px%27%20y%3D%270px%27%20enable-background%3D%27new%200%200%205%202%27%20xml%' +
    '3Aspace%3D%27preserve%27%20viewBox%3D%270%200%205%202%27%20preserveAspectRatio%3D%27none' +
    '%20slice%27%3E%3Ccircle%20cx%3D%271%27%20cy%3D%271%27%20r%3D%271%27%20fill%3D%27' +
    $color + '%27%2F%3E%3C%2Fsvg%3E';

  @return url($result);
}
